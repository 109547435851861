<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div class="title" v-show="edi_address_id&&!is_edi">
      <span class="title_left">修改收货人信息</span>
      <div class="title_right" @click="chooseAddress">
        <van-icon name="location" />
        <span>切换地址</span>
      </div>
    </div>
    <!-- 输入任意文本 -->
    <van-field v-model="receiver" label-align="left" label="收货人" placeholder="请输入真实姓名" />
    <!-- 输入手机号，调起手机号键盘 -->
    <van-field v-model="mobile" label-align="left" type="tel" label="手机号码" placeholder="请输入手机号" />
    <div class="sele">
      <span class="lable">选择省</span>
      <select ref="myOP1" v-model="sheng_name" @change="sele_change_sheng()">
        <option value>请选择</option>
        <option v-for="(item,index) in address_list_sheng" :key="index" @click="get_sheng_id(item.id)">{{ item.cname }}</option>
      </select>
    </div>
    <div class="sele">
      <span class="lable">选择市</span>
      <select ref="myOP2" v-model="shi_name" @change="sele_change_shi()">
        <option value>请选择</option>
        <option v-for="(item,index) in address_list_shi" :key="index">{{ item.cname }}</option>
      </select>
    </div>
    <div class="sele">
      <span class="lable">选择区</span>
      <select ref="myOP3" v-model="qu_name" @change="sele_change_qu()">
        <option value>请选择</option>
        <option v-for="(item,index) in address_list_qu" :key="index">{{ item.cname }}</option>
      </select>
    </div>

    <!-- 输入任意文本 -->
    <van-field v-model="text" label-align="left" label="详细地址" placeholder="道路、小区、单元室、小区门牌等" />
    <van-button round type="danger" class="btn_submit" block @click="add_address()">{{edi_address_id?'确认修改收货地址':'保存收货地址'}}</van-button>
  </div>
</template>

<script>
  import heads from '@/compontens/heads.vue'
  import { areaList } from '@vant/area-data'
  import { get_sheng, get_shi, add_addrss, getAddressInfo,editOrderAddress } from '@/api/my_user.js'
  import { Toast } from 'vant'
  export default {
    components: {
      heads
    },
    data () {
      return {
        text: '',
        order_id:this.$route.query.order_id,
        is_edi:this.$route.query.is_edi,
        areaList: areaList,
        value: '',
        showArea: false,
        mobile: '',
        title_msg: '添加地址',
        receiver: '',
        show: false,
        fieldValue: '',
        cascaderValue: '',
        address_list_sheng: [], // 省列表
        // 选项列表，children 代表子选项，支持多级嵌套
        sheng_name: '', // 省名称
        ssq: '',
        shengId: '', // 省ID
        address_list_shi: [{ cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }],
        shi_name: '', // 市名称
        address_list_qu: [{ cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }, { cname: '' }],
        shiId: '',
        quId: '',
        qu_name: '',
        edi_address_id:this.$route.query.one_id
      }
    },
    mounted () {
      this.getsheng()
      if (this.$route.query.one_id) {
        // 编辑
        this.title_msg = "修改地址"
        this.get_oldAddress()
      }
    },

    methods: {
      chooseAddress(){
        this.$router.replace({ path: '/index/express/address_management',
          query: {
            order_id: this.order_id,
            edi_address_id: this.edi_address_id
          }
        })
      },
      // 地址信息回写
      get_oldAddress () {
        var params = {
          id: this.$route.query.one_id
        }
        getAddressInfo(params).then((res) => {
          this.item = res.result
          this.receiver = this.item.receiver
          this.mobile = this.item.mobile
          this.sheng_name = this.item.province
          this.shengId = this.item.province_id
          this.shiId = this.item.city_id
          this.quId = this.item.county_id
          var params = {
            parent_id: res.result.province_id
          }
          get_sheng(params)
                  .then((res) => {
                    this.address_list_shi = res.result
                  })
                  .then(() => {
                    this.shi_name = this.item.city
                  })
          if (res.result.city_id) {
            var params1 = {
              parent_id: res.result.city_id
            }
            get_shi(params1).then((res) => {
              this.address_list_qu = res.result
            }).then(() => {
              this.qu_name = this.item.county
            })
          }
          this.text = res.result.detail_address
        })
      },
      // 获得区ID
      sele_change_qu () {
        if (this.qu_name !== '') {
          this.$refs.myOP3.style.color = '#323233'
          const obj2 = this.address_list_qu.find(item => {
            if (this.qu_name === item.cname) {
              return item
            }
          })
          this.quId = obj2.id
        } else {
          this.$refs.myOP3.style.color = '#ccc'
        }
      },
      // 获得市ID
      sele_change_shi () {
        if (this.shi_name !== '') {
          this.$refs.myOP2.style.color = '#323233'
          const obj1 = this.address_list_shi.find(item => {
            if (this.shi_name === item.cname) {
              return item
            }
          })
          this.shiId = obj1.id
          this.getqu()
        } else {
          this.$refs.myOP2.style.color = '#ccc'
        }
      },
      // 获取省id
      sele_change_sheng () {
        if (this.sheng_name !== '') {
          this.$refs.myOP1.style.color = '#323233'
          const obj = this.address_list_sheng.find(item => {
            if (this.sheng_name === item.cname) {
              return item
            }
          })
          this.shengId = obj.id
          this.getshi()
        } else {
          this.$refs.myOP1.style.color = '#ccc'
        }
      },
      // 获取省id对应的市列表
      getshi () {
        var params = {
          parent_id: this.shengId
        }
        get_sheng(params).then((res) => {
          this.address_list_shi = res.result
        })
      },
      // 获取市id对应的区列表
      getqu () {
        var params = {
          parent_id: this.shiId
        }
        get_shi(params).then((res) => {
          this.address_list_qu = res.result
        })
      },

      // 获取省
      getsheng () {
        get_sheng().then((res) => {
          this.address_list_sheng = res.result
        })
      },
      onConfirm (values) {
        this.value = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/')
        this.showArea = false
      },
      add_address () {
        if (this.receiver && this.mobile && this.shengId && this.shiId && this.text) {
          var params = {
            receiver: this.receiver,
            mobile: this.mobile,
            province_id: this.shengId,
            city_id: this.shiId,
            county_id: this.quId ? this.quId : 0,
            detail_address: this.text
          }
          if (this.$route.query.one_id) {
            params.id = this.$route.query.one_id
          }
          if (!this.order_id){
            add_addrss(params).then(res => {
              this.$router.go(-1)
            })
          }else {
            add_addrss(params).then(res => {
              let params1 = {
                order_id :this.order_id,
                address_id:this.edi_address_id,
              }
              editOrderAddress(params1).then(res => {
                sessionStorage.setItem('edi_address_id',this.edi_address_id)
                this.$router.go(-1)
              })
            })
          }
        } else {
          if (!this.receiver) {
            Toast.fail('请填写收件人')
          } else if (!this.mobile) {
            Toast.fail('请填写手机号')
          } else if (!this.shengId) {
            Toast.fail('请填写所在省市')
          } else if (!this.shiId) {
            Toast.fail('请填写所在省市')
          } else {
            Toast.fail('请填写详细地址')
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn_submit{
    width: 80%;
    background: #ff272c;
    position: absolute;
    left: 50%;
    margin-left: -40%;
    margin-bottom: 40px;
    bottom:0;
  }
  /deep/ .van-field__label {
    color: #333;
    font-weight: 700;
    font-size: 28px;
  }
  .title{
    display: flex;
    padding: 30px;
    justify-content: space-between;
    .title_left{
      height: 42px;
      line-height: 42px;
      font-size: 30px;
      color: #333333;
      font-weight: 700;
    }
    .title_right{
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #333333;
      span{
        margin-left: 6px;
      }
    }
  }
  .sele {
    display: flex;
    padding: 0px 16px;
    justify-content: flex-start;
    .lable{
      margin-left: 16px;
      display: flex;
      align-items: center;
      flex-shrink:0;
      left: 64px;
      color: #333;
      font-size: 28px;
      font-weight: 700;
    }
  }
  select {
    -webkit-appearance: none;
    color: #333;
    height: 70px;
    width: 490px;
    margin-left: 220px;
    border: none;
    background: #fff;
    padding-left: 8px;
    margin: 8px 0 8px 108px;
    font-size: 28px;
  }
  option {
    background: #fff;
    height: 300px;
    padding: 50px 0 50px 0;
  }
</style>
